.App {
  text-align: center;
}

#particles canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

span {
  font-size: 22px;
  color: #333;
  
}
