/* src/components/Footer.css */
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* background-color: #333; */
    color: white;
    text-align: center;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo {
    width: 80px; /* Adjust size as needed */
    margin-bottom: 10px;
  }
  