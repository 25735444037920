body{
    background-color: white;
}
.test{
    font-size: 25px;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
}
#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; /* Make sure particles are in the background */
  }
.Home {
    text-align: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
